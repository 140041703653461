<template>
  <v-container class="fill-height">
    <v-responsive class="d-flex align-center">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="auto" class="text-center">
          <div class="text-h4 mb-8">
            Hello, {{ userData.name }}!
            <div
              v-if="userData.status === 'completed' && !isSupraUser"
              class="text-h5"
            >
              Soon you will have active products
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center">
        <v-col
          cols="12"
          md="5"
          lg="4"
          v-for="element in computedCardElements"
          :key="element.id"
        >
          <v-card class="ma-4" rounded="xl" elevation="4">
            <v-img :src="element.img_src" height="auto"></v-img>
            <v-card-title> {{ element.title }} </v-card-title>
            <v-card-text class="text--dark_blue">
              <div>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </div>
            </v-card-text>
            <v-card-actions class="px-7 pb-11 d-flex justify-space-between">
              <v-badge
                :content="element.button_1_badge_val"
                :value="element.button_1_badge_val"
                color="error"
                overlap
              >
                <v-btn
                  v-if="element.button_1_text"
                  color="dark_blue"
                  dark
                  @click="redirectTo(element.button_1_action)"
                  size="large"
                  :disabled="element.button_1_is_disabled"
                  width="110px"
                >
                  {{ element.button_1_text }}
                </v-btn>
                <v-btn v-else style="visibility: hidden"></v-btn> </v-badge
              ><v-btn
                dark
                color="light_green"
                @click="redirectTo(element.button_2_action)"
                size="large"
                width="110px"
              >
                {{ element.button_2_text }}
              </v-btn></v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<style scoped></style>

<script>
import apiService from "@/services/apiService";
import endpoints from "@/utils/endpoints";
import Cookies from "universal-cookie";
export default {
  name: "HomePage",

  data: () => ({
    userData: {
      name: null,
      status: null,
      app_permitions: [],
    },
    isSupraUser: false,
    cookies: new Cookies(),
    cardElements: [
      {
        id: "2",
        title: "YMS Portal",
        description:
          "Some quick example text to build on the card title and make up the bulk of the card's content.",
        permission_name: "Yms",
        alt: "yms",
        img_src: endpoints.getUrlOfImages() + "cards-bg/YMS.png",
        is_disabled: false,
        button_1_badge_val: null,
        button_1_is_disabled: false,
        button_1_text: null,
        button_1_action: null,
        button_2_is_disabled: false,
        button_2_text: "Go",
        button_2_action: "/yms-portal/inyardstorage",
        supraAdminRequired: true,
        ishidden: false,
      },
      {
        id: "3",
        title: "User management",
        description:
          "Some quick example text to build on the card title and make up the bulk of the card's content.",
        permission_name: "UserManagement",
        alt: "users",
        img_src: endpoints.getUrlOfImages() + "cards-bg/User%20management.png",
        button_1_badge_val: 0,
        button_1_is_disabled: false,
        button_1_text: "pendings",
        button_1_action: "/user-management/unverified-users",
        button_2_is_disabled: false,
        button_2_text: "Go",
        button_2_action: "/user-management/verified-users",
        supraAdminRequired: true,
        ishidden: false,
      },
      {
        id: "4",
        title: "Carrier YMS Portal",
        description:
          "Some quick example text to build on the card title and make up the bulk of the card's content.",
        permission_name: "CarrierPortal",
        alt: "yms",
        img_src: endpoints.getUrlOfImages() + "cards-bg/YMS.png",
        is_disabled: false,
        button_1_badge_val: null,
        button_1_is_disabled: false,
        button_1_text: null,
        button_1_action: null,
        button_2_is_disabled: false,
        button_2_text: "Go",
        button_2_action: "/yms-carrier-portal/inyardstorage",
        ishidden: false,
      },
    ],
  }),

  created() {
    this.userData = this.$store.state.userData;
    this.isSupraUser = this.userData.type === "supra" ? true : false;

    this.getPendingUsersVal();
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent("AskUserFromPersonalizedPermission", {
          detail: {
            permission: "UserManagement",
          },
        })
      );
    }, 1500);
  },

  computed: {
    computedCardElements() {
      let finalList = [];

      window.addEventListener("responseOfPermissionAsked", (e) => {
        this.userData = e.detail;
        const hasPermission = this.userData.app_permitions;
        for (let card of this.cardElements) {
          if (
            (this.userData.app_permitions.HasAccess &&
              card.permission_name === "UserManagement") ||
            (hasPermission.hasAccess &&
              hasPermission.Resource("UserManagement"))
          ) {
            card.ishidden = true;
            finalList.push(card);
          } else if (
            this.userData.type === "YardOwner" &&
            card.permission_name === "Yms"
          ) {
            card.ishidden = true;
            finalList.push(card);
          } else if (
            this.userData.type === "Carrier" &&
            card.permission_name === "CarrierPortal"
          ) {
            card.ishidden = true;
            finalList.push(card);
          }
        }
      });

      return finalList;
    },
  },

  methods: {
    verifySupraAdmin: function (required) {
      return required ? this.isSupraUser : true;
    },

    hasPermission: function () {
      if (this.userData) {
        return this.userData.app_permitions.HasAccess &&
          this.userData.status === "Approved"
          ? true
          : false;
      }
    },

    redirectTo(url) {
      this.$router.push(url);
    },

    getPendingUsersVal: async function () {
      try {
        const response = await apiService.get(
          endpoints.getTotalUsersNotVerified(this.userData.scac_code),
          {}
        );
        if (response.data.data) {
          const pendingUsers = response.data.data.filter(
            (user) => user.status === "Created"
          );
          for (let card of this.cardElements) {
            if (card.permission_name === "UserManagement") {
              card.button_1_badge_val = pendingUsers.length;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
