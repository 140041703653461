const endpoints = {
  getTotalUsersNotVerified(scaccode) {
    return process.env.VUE_APP_GET_TOTAL_USERS_NOT_VERIFIED + scaccode;
  },
  getUrlOfImages() {
    return process.env.VUE_APP_GET_URL_OF_IMAGES;
  },
};

export default endpoints;
